<template>
  <!-- eslint-disable -->
  <div class="card-container" @click="handleOnClick">
    <!-- <div class="image-container">
      <img :src="data.image_url" alt="Property image" />
    </div> -->
    <div class="info-container">
      <!-- <div class="address-img-section">
        <i class="fa fa-map-marker" />
      </div> -->
      <div class="sub-info-section">
        <!-- <div class="address-block">
          <p>Address: {{ data.address }}</p>
        </div> -->
        <div class="sub-info-block">
          <!-- <div class="biling-block" v-if="data.days_left">
            <p class="green-text">{{ data.days_left }} days left.</p>
          </div> -->
          <div class="biling-block">
            <div v-if="data.canceled">
              <p>Will be canceled on</p>
              <p class="green-text">{{ showEndData(data.end_date) }}</p>
            </div>
            <div v-else>
              <p>Next Billing Date</p>
              <p class="green-text">{{ showEndData(data.end_date) }}</p>
            </div>
          </div>

          <!-- <p v-if="data.days_left && !data.canceled">
            $2.99 every 30 days
          </p> -->
          <p v-if="!data.canceled">
            $2.99 per month
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import VueSkeletonLoader from "skeleton-loader-vue";

export default {
  name: "SubscriptionCardItem",
  props: {
    data: Object,
    // showPopup: Boolean,
  },
  components: {
    VueSkeletonLoader,
  },
  data() {
    return {
      callback: {
        type: Function,
      },
    };
  },

  computed: {
    ...mapGetters("auth", ["isCognitoUserLoggedIn", "cognitoUser"]),
  },

  methods: {
    handleOnClick() {
      this.$emit("onDialogClick", this.data);
    },
    showEndData(end_date) {
      var formatedDate = new Date(end_date);
      formatedDate = formatedDate
        .toLocaleDateString("en-US", {
          timeZone:"UTC",
          month: "short",
          day: "2-digit",
          year: "numeric",
        })
        .replace(",", "");
      return formatedDate;
    },
  },
};
</script>

<style>
.card-container {
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  padding: 2rem 4rem;
}

.card-container:hover {
  cursor: pointer;
  box-shadow: 0px 5px 10px -2px #d2d2d2;
  transition-duration: 150ms;
}

.image-container {
  height: 45%;
}

.image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.info-container {
  height: 55%;
  flex-direction: row;
  display: flex;
}

.address-img-section {
  display: flex;
  justify-content: center;
  min-width: 30px;
  margin-top: 1.3rem;
  color: #808080;
}

.sub-info-section {
  display: flex;
  flex-direction: column;
  /* padding-top: 1rem; */
}

.address-block {
  margin-bottom: 1rem;
}

.address-block p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 0 !important;
  margin: 0 !important;
}

/* .sub-info-block {
} */
.biling-block {
  font-size: 1.5rem;
}

.sub-info-block p {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 18px;
  padding: 0.5rem !important;
  margin: 0 !important;
}

p.green-text {
  font-size: 1.2rem;
  color: #24cb43;
}

@media screen and (max-width: 400px) {
  .card-container {
    padding: 0.5rem;
  }
}
</style>
