<template>
  <!-- eslint-disable -->
  <div class="main-section">
    <SubscriptionInfoPopup :dialog.sync="dialog" :data="dialogData" />

    <div v-if="isLoading">
      <page-loader :loading="isLoading" />
    </div>
    <div v-else>
      <!-- <p class="text-title">Subscriptions</p> -->
      <h1 class="text-title">Subscription</h1>
      <div v-if="userSubsData">
        <div class="center-container">
          <SubscriptionCardItem
            :data="userSubsData"
            @onDialogClick="handleOnDialogClick"
          />
        </div>
      </div>
      <div v-else>
        <p style="text-align: center;">
          You don't have the active subscription yet
        </p>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import VueSkeletonLoader from "skeleton-loader-vue";
import SubscriptionCardItem from "./SubscriptionCardItem.vue";

export default {
  name: "SubscriptionInfo",
  components: {
    VueSkeletonLoader,
    SubscriptionCardItem,
    PageLoader: () => import("@/components/PageLoader"),
    SubscriptionInfoPopup: () => import("./SubscriptionInfoPopup"),
  },
  data() {
    return {
      isLoading: true,
      userSubsData: null,
      dialog: false,
      dialogData: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["isCognitoUserLoggedIn", "cognitoUser"]),
  },
  mounted() {
    // Get data from API
    axios
      .get("https://api.honely.com/dev/payments/v2/fetch-subscriptions", {
        headers: {
          Authorization:
            "Bearer " + this.cognitoUser.signInUserSession.idToken.jwtToken,
        },
      })
      .then((response) => {
        this.isLoading = false;
        this.userSubsData = response.data.data.subscription_id
          ? response.data.data
          : null;
      })
      .catch((error) => {
        this.isLoading = false;
        console.log("[ERROR] Failed to fetch forecast data");
        console.log(error);
      });
  },
  methods: {
    handleOnDialogClick(data) {
      // console.log("Click from parent", data.address);
      this.dialogData = data;
      this.dialog = true;
    },
  },
};
</script>

<style>
.main-section {
  min-height: 50vh;
  margin: 2rem;
}

.text-title {
  font-weight: 600;
  font-size: 2.5rem;
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 2rem;
  line-height: 140%;
  margin-bottom: 1.5rem;
}

.center-container {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
  justify-content: center;
  grid-auto-columns: 450px;
  grid-auto-rows: 300px;
  grid-gap: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .text-title {
    padding-top: 1rem;
  }
}
</style>
